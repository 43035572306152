//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Venues, Address } from '@/services/api.service';
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox';
import 'vue-mapbox/dist/vue-mapbox.css';

let mapInstance = null;

export default {
  name: 'QVenueCreateModal',
  props: ['show', 'initial'],
  components: {
    MglMap,
    MglNavigationControl,
    MglMarker,
  },
  data() {
    return {
      showCreateVenueModal: true,
      venue: {
        title: this.initial,
        description: '',
        location: {
          latitude: null,
          longitude: null,
        },
        venue_type_id: null,
        contact: null,
        zip_code: null,
        street: null,
        house_number: null,
        town: null,
        country: null,
        name: null,
      },
      errors: {},
      addressExists: false,
      selectedLatLon: {
        latitude: null,
        longitude: null,
      },
      resolvedAddress: null,
      addressSearchResult: [],
      search_address_name: '',
      address_search_in_progress: false,
      address_selected: false,
      map: {
        accessToken:
          'pk.eyJ1IjoiYWRhdG8iLCJhIjoiY2t2cjRqOGZ1MG55YTJycXB4M3gzOHI5biJ9.0a9Q34fyXnuLz06vY_KUCg',
        mapStyle: 'mapbox://styles/mapbox/streets-v9',
        center: [14, 50],
        zoom: 3,
        map: null,
      },
      tmpImg: null,
      inProgress: false,
      successCreated: false,
      isAddressInputEnabled: true,
    };
  },
  methods: {
    closeModal({ force }) {
      if (force === true) {
        this.$emit('close', null);
      }
    },
    setAddress(addr) {
      this.search_address_name = addr.name;
      this.venue.street = addr.street;
      this.venue.house_number = addr.houseNo;
      this.venue.town = addr.town;
      this.venue.country = addr.country;
      this.venue.zip_code = addr.zipCode;
      this.venue.name = addr.name;
      this.map.center = [addr.longitude, addr.latitude];
      this.map.zoom = 16;
      this.address_selected = true;
      this.selectedLatLon = {
        latitude: addr.latitude,
        longitude: addr.longitude,
      };
      this.resolvedAddress = addr;
    },
    removeImage() {
      this.venue.existingImage = false;
      this.venue.image = null;
      this.tmpImg = null;
    },
    validateAddressFn() {
      return new Promise((resolve, reject) => {
        this.isAddressInputEnabled = false;
        Address.get({
          searchString: this.search_address_name,
          language: this.$i18n.locale,
        }).then(
          (res) => {
            this.isAddressInputEnabled = true;
            if (!res || !res.data || !res.data.data) reject(false);
            if (!Object.keys(res.data.data.searchLocation).length)
              return reject(false);
            else return resolve(res.data.data.searchLocation);
          },
          (err) => reject(err)
        );
      });
    },
    onFileChanged(event) {
      let file = event.target.files[0];
      const reader = new FileReader();
      let that = this;
      reader.onload = (e) => {
        that.tmpImg = e.target.result;
        this.$forceUpdate(); // didnt redraw
      };
      reader.readAsDataURL(file);
      this.venue.image = file;
      this.venue.imageChanged = true;
    },
    mapInit(map) {
      mapInstance = map;
      mapInstance.map.scrollZoom.disable();
      mapInstance.map.resize();
    },
    moveMap() {
      let center = [
        this.selectedLatLon.longitude,
        this.selectedLatLon.latitude,
      ];
      mapInstance.map.flyTo({
        center: center,
      });
      this.map.center = center;
    },
    moveMarker(map) {
      this.map.center = [
        map.mapboxEvent.lngLat.lng,
        map.mapboxEvent.lngLat.lat,
      ];
      mapInstance.map.flyTo({
        center: this.map.center,
      });
      this.selectedLatLon.longitude = map.mapboxEvent.lngLat.lng;
      this.selectedLatLon.latitude = map.mapboxEvent.lngLat.lat;
    },
    mapMoveEnded(map) {
      const center = map.marker.getLngLat();
      this.selectedLatLon = {
        latitude: center.lat,
        longitude: center.lng,
      };
    },
    /**
     * form validations
     **/
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.errors = {};
        if (!this.venue.title) {
          this.errors.titleMissing = true;
          reject(false);
        }
        resolve(true);
      });
    },
    useVenue() {
      this.$emit('close', this.successCreated.data.result);
    },

    /**
     * onSubmit handler
     */
    async venueCreateSubmit() {
      this.inProgress = true;
      this.$q.loading.show();

      this.processStep1()
        .then(() => {
          return this.saveVenue();
        })
        .then((res) => {
          this.inProgress = false;
          this.$q.loading.hide();
          this.$emit('close', res);
        })
        .catch((err) => {
          this.$q.notify({
            type: 'negative',
            message: this.$i18n.t('venues.create.error.create_failed'),
            caption: err,
          });
          this.$q.loading.hide();
          this.inProgress = false;
        });
    },

    async processStep1() {
      return this.validateStep1();
    },
    async saveVenue() {
      return new Promise((resolve, reject) => {
        Object.keys(this.venue).forEach(
          (key) => this.venue[key] == null && delete this.venue[key]
        );

        let dataObj = {
          title: this.venue.title, // required	Title of the venue
          description: this.venue.description, //	Description of the venue
          longitude: parseFloat(this.selectedLatLon.longitude), //.toFixed(6), //	Longitude of the venue location (format is (-)XXX.XXXXXX )
          latitude: parseFloat(this.selectedLatLon.latitude), //.toFixed(6), //	Latitude of the venue location (format is (-)XXX.XXXXXX )
          address: this.venue.name,
        };

        if (this.venue.imageChanged && this.venue.image) {
          dataObj.image = this.venue.image;
        }

        Venues.add(dataObj).then(
          (venue) => {
            resolve(venue);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
  watch: {
    search_address_name(val) {
      if (!val) return;
      this.address_search_in_progress = true;
      this.validateAddressFn()
        .then((addr) => {
          if (Array.isArray(addr)) {
            this.addressSearchResult = addr;
          } else {
            // is single address
            this.addressSearchResult = [addr];
          }
          this.address_search_in_progress = false;
        })
        .catch(() => {
          this.address_search_in_progress = false;
          this.addressSearchResult = [];
        });
    },
  },
  mounted() {},
  created() {
    this.mapbox = Mapbox;
  },
};
